import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "./IconButton";

export default function Logo() {
  const location = useLocation();
  const navigate = useNavigate();

  const isHomePage = location.pathname === "/";
  const handleXIconClick = () => {
    window.open("http://x.com/poortheai", "_blank", "noopener,noreferrer");
  };

  const handleTGIconClick = () => {
    window.open("https:///t.me/pooraisol", "_blank", "noopener,noreferrer");
  };
  const publicUrl = process.env.REACT_APP_PUBLIC_URL || "";

  return (
    <header>
      {!isHomePage && (
        <IconButton
          src={`${publicUrl}/Assets/arrow_back.svg`}
          alt="Go Back"
          onClick={() => navigate(-1)}
        />
      )}
      <a href={`${publicUrl}`}>
        <button className="logoHeader">
          <img
            src={`${publicUrl}/Assets/The Poor Ai Logo.svg`}
            alt="The Poor AI"
          />
          <h1 className="logo">The Poor AI</h1>
        </button>
      </a>
      <div className="actions">
        <IconButton
          src={`${publicUrl}/Assets/tgIcon.svg`}
          alt="The Poor AI Telegram Account"
          onClick={handleTGIconClick}
        />
        <IconButton
          src={`${publicUrl}/Assets/Xicon.svg`}
          alt="The Poor AI X Account"
          onClick={handleXIconClick}
        />
      </div>
    </header>
  );
}
