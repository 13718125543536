import Header from "../Components/Header";
import Content from "../Components/Content";
import ChatBanner from "../Components/ChatBanner";
import FooterText from "../Components/FooterText";

export default function WelcomeAi() {
  return (
    <>
      <Header />
      <Content />
      <ChatBanner />
      <FooterText />
    </>
  );
}
