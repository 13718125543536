import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WelcomeAi from "./Pages/WelcomeAi";
import ChatAi from "./Pages/ChatAi";
import "@nlux/themes/nova.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<WelcomeAi />} />
          <Route path="/chat" element={<ChatAi />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
