export default function FooterText() {
  return (
    <main>
      <p className="paragraph-secondary">
        <span>Mention me on twitter[X] </span>
        <span>
          <a href="http://x.com/poortheai" target="_blank" rel="noreferrer">
            @poortheai
          </a>
        </span>
        <span>
          {" "}
          with your questions, and I might eventually respond, maybe.
        </span>
      </p>
    </main>
  );
}
