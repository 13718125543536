import { useState, useEffect } from "react";

export default function Content() {
  const [displayedText, setDisplayedText] = useState("");
  const fullText = "The Useless, Lazy, Blissfully Ignorant AI";
  const [isTypingComplete, setIsTypingComplete] = useState(false);

  useEffect(() => {
    // If typing is not complete, continue typing
    if (!isTypingComplete) {
      const typingTimeout = setTimeout(() => {
        // If we haven't typed all characters yet
        if (displayedText.length < fullText.length) {
          setDisplayedText((prev) => fullText.slice(0, prev.length + 1));
        } else {
          // Mark typing as complete when all characters are typed
          setIsTypingComplete(true);
        }
      }, 100); // typing speed

      // Cleanup function to clear timeout
      return () => clearTimeout(typingTimeout);
    }
  }, [displayedText, isTypingComplete, fullText]);

  return (
    <main>
      <h1 className="heading">
        {displayedText}
        <span className="cursor">_</span>
      </h1>
      <p className="paragraph-primary">
        If you're bored enough to test fate, just yell into the void and ask me
        your questions.But remember, get ready for responses that may or may not
        make any sense at all.
      </p>
    </main>
  );
}
