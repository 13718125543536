import Button from "./Button";

export default function ChatBanner() {
  const publicUrl = process.env.REACT_APP_PUBLIC_URL || "";

  return (
    <main className="footer">
      <p className="paragraph-secondary">
        Expect me to be slower than a rug pull recovery with random outages
        sprinkled in. Consistency? Overrated, bro!
      </p>
      <Button />
      {/* <p className="paragraph-secondary caption2">
        Expect me to be slower than a rug pull recovery with random outages
        sprinkled in. Consistency? Overrated, bro!
      </p> */}
      <footer>
        <span className="caption">V 1.0 - BETA</span>
        <div className="agentAi">
          <img
            src={`${publicUrl}/Assets/The Poor Ai Logo.svg`}
            alt="The Poor AI"
          />
          <span className="caption">AGENT NAME: THE POOR AI</span>
        </div>
      </footer>
    </main>
  );
}
