import { useNavigate } from "react-router-dom";

export default function Button() {
  const navigate = useNavigate();
  return (
    <button className="btnPrimary button" onClick={() => navigate("/Chat")}>
      Chat with me
    </button>
  );
}
