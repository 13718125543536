// import ChatComponent from "../Components/ChatComponent";
import Header from "../Components/Header";
import { AiChat } from "@nlux/react";
import { send } from "../send";
import { personas } from "../personas";
import { useAsStreamAdapter } from "@nlux/react";

export default function ChatAi() {
  const adapter = useAsStreamAdapter(send, []);

  return (
    <>
      <Header />
      <AiChat adapter={adapter} personaOptions={personas} />
      {/* <ChatComponent /> */}
    </>
  );
}
