import { PersonaOptions } from "@nlux/react";

const assistantAvatar = (
  <img
    src={`${process.env.REACT_APP_PUBLIC_URL}/Assets/The%20Poor%20Ai%20Logo.svg`}
    alt="The Poor AI"
  />
);

export const personas: PersonaOptions = {
  assistant: {
    avatar: assistantAvatar,
    tagline:
      "Hey, fam! I'm POOR Bot, your unreliable digital sidekick. Expect me to be slower than a rug pull recovery with random outages sprinkled in. Consistency? Overrated, bro!",
  },
  user: {
    name: "You",
  },
};
