// import Border from "./Border";
import Logo from "./Logo";

export default function Header() {
  return (
    <>
      <Logo />
    </>
  );
}
